import React from 'react';
import Svg from '@grebban/react-core-components/svg/Svg';
import Path from '@grebban/react-core-components/svg/Path';

export interface PlayProps {
    color?: string;
    height?: string;
    width?: string;
    viewBox?: string;
    fill?: string;
}

const Play: React.FunctionComponent<PlayProps> = ({
    color = 'var(--color-base-white)',
    height = '12px',
    width = '12px',
    viewBox = '0 0 12 12',
    fill = 'none',
    ...rest
}) => (
    <Svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <Path d="M3 1V11L11 6L3 1Z" stroke={color} fill={fill} />
    </Svg>
);

export default Play;
