import React from 'react';
import Svg from '@grebban/react-core-components/svg/Svg';
import Path from '@grebban/react-core-components/svg/Path';

export interface PauseProps {
    color?: string;
    height?: string;
    width?: string;
    viewBox?: string;
}

const Pause: React.FunctionComponent<PauseProps> = ({
    color = 'var(--color-base-white)',
    height = '12px',
    width = '12px',
    viewBox = '0 0 12 12',
    ...rest
}) => (
    <Svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <Path d="M9 0V12" stroke={color} />
        <Path d="M3 0V12" stroke={color} />
    </Svg>
);

export default Pause;
