import React, { useEffect, useRef, useState } from 'react';
import styled from '@grebban/style-system-react';

import FullWidth from '@sportson/core-web/App/layouts/FullWidth';
import CmsBlocks from '@sportson/core-web/libs/wordpress/content/CmsBlocks';
import TouchableSlider from '@sportson/core-web/components/sliders/TouchableSlider';

import { type WordpressPanelBlock } from 'libs/wordpress/content/Blocks/Panel';
import { type WordpressGenericFlexibleContent } from 'libs/wordpress/types/acf/fields';
import Button from 'components/Button';
import { ArrowIcon } from 'assets/media/icons/ArrowIcon';
import usePdpSlider from 'Views/Product/MediaGallery/usePdpSlider';
import { above } from '@sportson/core-web/utils/mediaqueries';
import { inServer } from '@sportson/core-web/constants';
import Pause from 'assets/media/icons/Pause';
import Play from 'assets/media/icons/Play';
import { useTranslation } from 'react-i18next';
import useBreakpoint from '@sportson/core-web/hooks/useBreakpoint';
import { type WordpressStandardModuleSettings } from '../../types/contentSystem/groups';

import { transformBackgroundColor } from '../../utils/transformBackground';

const ContentWrapper = styled('div')`
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10;

    /* Element wrapping slider component (inside TouchableSlider) */
    .slider-component-wrapper {
        max-width: 100%;
    }
    ${({ isHero }) =>
        isHero &&
        `
        width: 100vw;
        position: relative;
        left: calc(-50vw + 50%);
    `}
`;

const SliderNavigation = styled('div')`
    width: 100%;
    display: flex;
    gap: 40px;
    position: absolute;
    bottom: 0;
    z-index: var(--zindex-middleground);

    ${above['desktop.lg']} {
        ${({ isHero }) =>
            isHero &&
            `
            left: 50%;
            transform: translateX(-50%);
            max-width: var(--site-corridor-max-width);
    }`}
    }
`;

const IndicatorCounter = styled('div')`
    width: 100%;
    display: flex;
    gap: 24px;
    align-items: center;
`;

const Indicator = styled('div')`
    height: 2px;
    width: 100%;
    border-radius: 8px;
    backdrop-filter: blur(12px);
    background-color: var(--color-transparency-white-30);
    & > div {
        width: ${({ progress }) => progress}%;
        transition: width 0.1s linear;
    }
`;

const ButtonWrapper = styled('div')`
    align-items: center;
    gap: 16px;
`;

const Step = styled('div')`
    height: 100%;
    border-radius: 8px;
    background-color: var(--color-base-white);
`;

const Slide = styled('div')`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    border: none;
`;

interface WordpressPanelsModuleContentPanels extends WordpressGenericFlexibleContent {
    layout: 'panels';
    data: WordpressPanelBlock;
}
// TODO: NOTE: Uncomment when implemented in wordpress ACF
// interface WordpressPanelsModuleContentCallToAction extends WordpressGenericFlexibleContent {
//     layout: 'callToAction';
//     data: WordpressCallToActionBlock;
// }

export type WordpressPanelsModulePanelsContentBlock = WordpressPanelsModuleContentPanels;
// | WordpressPanelsModuleContentCallToAction;

export interface PanelsModuleProps {
    data: {
        panels: WordpressPanelsModulePanelsContentBlock[];
        // panels: (WordpressPanelsModulePanelsContentBlock | WordpressPanelsModuleContentCallToAction)[];
        moduleSettings: WordpressStandardModuleSettings;
    };
    isHero?: boolean;
    layout: 'panels';
}

const PanelsModule: React.FC<PanelsModuleProps> = ({ data, isHero, layout }) => {
    const isMobile = useBreakpoint({ to: 'desktop.sm' });
    const { moduleSettings, panels } = data;
    const { backgroundColor } = moduleSettings;
    const isSlider = panels.length > 1;
    const [sliderProps, sliderOptions] = usePdpSlider();
    const { t } = useTranslation();
    const { activeIndex, slideTo } = sliderOptions;
    const [isPaused, setIsPaused] = useState(false);
    const [progress, setProgress] = useState(0);
    const intervalRef = useRef<number | null>(null);

    // @TODO Implement background, spacing, ratio and visibility
    const moduleBgColor = transformBackgroundColor(backgroundColor).backgroundColor;

    useEffect(() => {
        const delay = 4000;
        const updateProgress = () => {
            if (!isPaused) {
                setProgress((prevProgress) => {
                    const newProgress = prevProgress + 100 / (delay / 10);
                    if (newProgress >= 100) {
                        slideTo((activeIndex + 1) % panels.length);
                        return 0;
                    }
                    return newProgress;
                });
            }
        };

        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = window.setInterval(updateProgress, 10);

        return () => {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
            }
        };
    }, [isPaused, activeIndex, slideTo, panels.length]);

    useEffect(() => {
        if (!isPaused) {
            const delay = 4000;
            const timer = setTimeout(() => {
                slideTo((activeIndex + 1) % panels.length);
            }, delay);

            return () => clearTimeout(timer);
        }
    }, [isPaused, activeIndex, slideTo, panels.length]);

    return (
        <FullWidth
            height="calc(100vh - var(--header-height))"
            backgroundColor={moduleBgColor || 'var(--color-base-black)'}
        >
            <ContentWrapper height="100%" isHero={isHero}>
                {isSlider ? (
                    <>
                        <SliderNavigation p={['0 16px 24px', null, null, '0 40px 24px']} isHero={isHero}>
                            <IndicatorCounter>
                                {panels.map((panel, index) => (
                                    <Indicator
                                        key={`slide-step-${index}`}
                                        progress={activeIndex === index ? progress : 0}
                                    >
                                        <Step />
                                    </Indicator>
                                ))}
                            </IndicatorCounter>
                            {isMobile ? (
                                <Button
                                    aria-label={isPaused ? t('aria_labels.play_slider') : t('aria_labels.pause_slider')}
                                    size="xsmall"
                                    theme="secondaryBlurred"
                                    onClick={() => setIsPaused(!isPaused)}
                                >
                                    {isPaused ? <Play fill="none" /> : <Pause />}
                                </Button>
                            ) : (
                                <ButtonWrapper display="flex">
                                    <Button
                                        aria-label={t('aria_labels.go_to_previous_slide')}
                                        size="xsmall"
                                        theme="secondaryBlurred"
                                        onClick={() =>
                                            slideTo(activeIndex - 1 < 0 ? panels.length - 1 : activeIndex - 1)
                                        }
                                    >
                                        <ArrowIcon color="var(--color-base-white)" direction="left" />
                                    </Button>
                                    <Button
                                        aria-label={
                                            isPaused ? t('aria_labels.play_slider') : t('aria_labels.pause_slider')
                                        }
                                        size="xsmall"
                                        theme="secondaryBlurred"
                                        onClick={() => setIsPaused(!isPaused)}
                                    >
                                        {isPaused ? <Play fill="none" /> : <Pause />}
                                    </Button>
                                    <Button
                                        aria-label={t('aria_labels.go_to_next_slide')}
                                        size="xsmall"
                                        theme="secondaryBlurred"
                                        onClick={() => slideTo(activeIndex + 1 >= panels.length ? 0 : activeIndex + 1)}
                                    >
                                        <ArrowIcon color="var(--color-base-white)" />
                                    </Button>
                                </ButtonWrapper>
                            )}
                        </SliderNavigation>

                        <TouchableSlider isInfinite {...sliderProps} gap="0">
                            {panels.map((panel, index) => (
                                <Slide key={`slide-block-${index}`}>
                                    <CmsBlocks
                                        data={panel}
                                        layout={layout}
                                        columnLayout={layout}
                                        isSlider
                                        isHero={isHero}
                                    />
                                </Slide>
                            ))}
                        </TouchableSlider>
                    </>
                ) : (
                    <CmsBlocks data={panels[0]} layout={layout} columnLayout={layout} />
                )}
            </ContentWrapper>
        </FullWidth>
    );
};

export default PanelsModule;
