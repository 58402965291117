import { useCallback, useRef, useState } from 'react';
import { inServer } from '@sportson/core-web/constants';

const usePdpSlider = () => {
    const ref = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const setRef = useCallback((node) => (ref.current = node), []);
    const props = { ref: setRef, onSlideChange: setActiveIndex };
    const iframes = inServer ? [] : document.querySelectorAll('.ifrm');
    const video = inServer ? [] : document.querySelectorAll('.vid');

    const stopVideos = () => {
        iframes.forEach((iframe) => {
            // Pauses yt-video when user changes slide without adding to history-stash.
            // Only works with "?enablejsapi=1"-suffix on the url-src.
            iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
        });
        video.forEach((v) => {
            v.pause();
        });
    };
    if (iframes.length || video.length) {
        stopVideos();
    }

    const isThumbnailActive = (index, slidesPerView, numberOfSlides) =>
        (activeIndex === null && index === 0) ||
        activeIndex === index ||
        (activeIndex === -1 && index === numberOfSlides - 1) ||
        (activeIndex === -2 && index === numberOfSlides - 2) ||
        (slidesPerView === 2 &&
            (activeIndex === index - 1 ||
                (activeIndex === numberOfSlides - 1 && index === 0) ||
                (activeIndex === -1 && index === numberOfSlides) ||
                (activeIndex === -2 && index === numberOfSlides - 1)));

    return [
        props,
        {
            activeIndex,
            isThumbnailActive,
            slidePrev: () => ref.current?.slidePrev(),
            slideNext: () => ref.current?.slideNext(),
            slideTo: (index) => ref.current?.slideTo(index),
        },
    ];
};

export default usePdpSlider;
